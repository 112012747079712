import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Collector } from "typings/Collector";
import { ServiceRequest } from "typings/ServiceRequest";
import { DialogTopics, ServiceRequestTopics } from "typings/Topics";
import { Vehicle } from "typings/Vehicle";

const ServiceRequestModal = lazy(
  () => import("components/ServiceRequest/dialogs/ServiceRequestModal"),
);

export const ServiceRequestDialogs = () => {
  const { object: toEdit, handleClose: handleCloseAdd } =
    useModalWithSubscription<{
      request?: ServiceRequest;
      vehicleId?: Vehicle["id"];
      collectorId?: Collector["id"];
    }>(DialogTopics.EditServiceRequest, ServiceRequestTopics.Updated);

  return (
    <SuspenseWrapper>
      {!!toEdit && <ServiceRequestModal {...toEdit} onClose={handleCloseAdd} />}
    </SuspenseWrapper>
  );
};
