export enum AppTopics {
  AppNotificationCreated = "AppNotificationCreated",
  NavigationRequested = "NavigationRequested",
  Error = "AppError",
}

export const appTopicWithLayout = (layout: string) =>
  `${AppTopics.AppNotificationCreated}.${layout}`;

export enum GarageTopics {
  Updated = "GarageUpdated",
}

export enum CollectorTopics {
  Added = "CollectorAdded",
  CrewMembershipAdded = "CrewMembershipAdded",
  CrewInvitationSent = "CrewInvitationSent",
  CrewMembershipRemoved = "CrewMembershipRemoved",
  EmailChangeSent = "EmailChangeSent",
  InvitationCreated = "InvitationCreated",
  ListViewUpdated = "ListViewUpdated",
  MfaDisabled = "MfaDisabled",
  MfaEnabled = "MfaEnabled",
  PaymentMethodAdded = "PaymentMethodAdded",
  PaymentMethodRemoved = "PaymentMethodRemoved",
  Removed = "CollectorRemoved",
  TagCreated = "TagCreated",
  TagDeleted = "TagDeleted",
  TagRenamed = "TagRenamed",
  TagsChanged = "TagsChanged",
  TierSelected = "TierSelected",
  Updated = "CollectorUpdated",
}

export enum VehicleTopics {
  Added = "VehicleAdded",
  CollectionsUpdated = "VehicleCollectionsUpdated",
  ConsignmentUpdated = "VehicleConsignmentUpdated",
  Deleted = "VehicleDeleted",
  ExternalLinksUpdated = "VehicleExternalLinksUpdated",
  HistoryRecordDeleted = "VehicleHistoryRecordDeleted",
  HistoryRecordRecovered = "VehicleHistoryRecordRecovered",
  HistoryRecordUpdated = "VehicleHistoryRecordUpdated",
  LocationUpdated = "VehicleLocationUpdated",
  MediaUpdated = "VehicleMediaUpdated",
  PricePointUpdated = "VehiclePricePointUpdated",
  Recovered = "VehicleRecovered",
  SpecsUpdated = "VehicleSpecsUpdated",
  StatusUpdated = "VehicleStatusUpdated",
  TagsUpdated = "VehicleTagsUpdated",
  TechnicalDataUpdated = "VehicleTechnicalDataUpdated",
  Updated = "VehicleUpdated",
  VaultMediaRecovered = "VehicleVaultMediaRecovered",
  VaultUpdated = "VehicleVaultUpdated",
  VehiclesImported = "VehiclesImported",
  WithdrawnFromOrganization = "VehicleWithdrawnFromOrganization",
}

export enum CollectionTopics {
  Added = "CollectionAdded",
  Deleted = "CollectionDeleted",
  Updated = "CollectionUpdated",
  VehiclesUpdated = "CollectionVehiclesUpdated",
}

export enum ListingTopics {
  Delisted = "ListingDelisted",
  MediaUpdated = "ListingMediaUpdated",
  Updated = "ListingUpdated",
}

export enum WishlistTopics {
  Deleted = "WishlistDeleted",
  MediaUpdated = "WishlistMediaUpdated",
  Updated = "WishlistUpdated",
}

export enum LocationTopics {
  Added = "LocationAdded",
  Deleted = "LocationDeleted",
  MediaUpdated = "LocationMediaUpdated",
  Updated = "LocationUpdated",
  VehiclesUpdated = "LocationVehiclesUpdated",
}

export enum ReminderTopics {
  Deleted = "ReminderDeleted",
  Updated = "ReminderUpdated",
}

export enum ServiceTopics {
  Deleted = "ServiceDeleted",
  Updated = "ServiceUpdated",
}

export enum ServiceRequestTopics {
  Deleted = "ServiceRequestDeleted",
  Updated = "ServiceRequestUpdated",
}

export enum HistoryRecordTopics {
  Deleted = "HistoryRecordDeleted",
  DocumentsUpdated = "HistoryRecordDocumentsUpdated",
  MediaUpdated = "RecordMediaUpdated",
  Recovered = "HistoryRecordRecovered",
  Updated = "HistoryRecordUpdated",
}

export enum DataRoomTopics {
  Deleted = "DataRoomDeleted",
  Updated = "DataRoomUpdated",
}

export enum DataRoomMembershipTopics {
  Revoked = "DataRoomMembershipRevoked",
  Withdrawn = "DataRoomMembershipWithdrawn",
}

export enum AdminTopics {
  UserPaywallChangeAction = "UserPaywallChangeAction",
}

export enum UserTopics {
  AccountDeleted = "UserAccountDeleted",
  Created = "UserCreated",
  LoggedIn = "UserLoggedIn",
  LoggedOut = "UserLoggedOut",
  LoginRequired = "UserLoginRequired",
  MfaConfirmed = "MfaConfirmed",
  MfaRequired = "MfaRequired",
  NewsletterSignedUp = "NewsletterSignedUp",
  OverQuota = "OverQuota",
  SignedUp = "UserSignedUp",
  SignUpRequired = "UserSignUpRequired",
  StartedCuration = "StartedCuration",
  StoppedCuration = "StoppedCuration",
  TokenExpired = "TokenExpired",
  TokenRefreshed = "TokenRefreshed",
  Unauthorized = "Unauthorized",
  Updated = "UserUpdated",
}

export enum DialogTopics {
  AcceptInterest = "AcceptInterest",
  AddCollector = "AddCollector",
  AddDealer = "AddDealer",
  AddListing = "AddListing",
  AddMembership = "AddMembership",
  AddPaymentMethod = "AddPaymentMethod",
  AddUser = "AddUser",
  AddVehicle = "AddVehicle",
  AddWishlist = "AddWishlist",
  AssignOrganization = "AssignOrganization",
  CancelNegotiation = "CancelNegotiation",
  ChangeEmail = "ChangeEmail",
  Close = "Close",
  CloseAppDrawer = "CloseAppDrawer",
  CompleteNegotiation = "CompleteNegotiation",
  CreateInvitation = "CreateInvitation",
  CreateTag = "CreateTag",
  DataRoomAddMembership = "DataRoomAddMembership",
  DelistListing = "DelistListing",
  EditCollection = "EditCollection",
  EditCollector = "EditCollector",
  EditDataRoom = "EditDataRoom",
  EditHistoryRecord = "EditHistoryRecord",
  EditHistoryRecordGallery = "EditHistoryRecordGallery",
  EditListing = "EditListing",
  EditListingDocuments = "EditListingDocuments",
  EditListingExternalLinks = "EditListingExternalLinks",
  EditListingGallery = "EditListingGallery",
  EditLocation = "EditLocation",
  EditLocationGallery = "EditLocationGallery",
  EditPassphrase = "EditPassphrase",
  EditReminder = "EditReminder",
  EditService = "EditService",
  EditServiceRequest = "EditServiceRequest",
  EditTags = "EditTags",
  EditVehicle = "EditVehicle",
  EditVehicleData = "EditVehicleData",
  EditVehicleExternalLinks = "EditVehicleExternalLinks",
  EditVehicleGallery = "EditVehicleGallery",
  EditVehiclePricePoint = "EditVehiclePricePoint",
  EditVehicleSpecs = "EditVehicleSpecs",
  EditVehicleStatus = "EditVehicleStatus",
  EditVehicleTechnicalData = "EditVehicleTechnicalData",
  EditWishlist = "EditWishlist",
  EditWishlistGallery = "EditWishGallery",
  EditWishlistSpecs = "EditWishlistSpecs",
  EnterPassphrase = "EnterPassphrase",
  ImportVehicles = "ImportVehicles",
  ManageAssignments = "ManageAssignments",
  ManageCollection = "ManageCollection",
  ManageLocation = "ManageLocation",
  ManageSubscription = "ManageSubscription",
  ManageVehicleCollections = "ManageVehicleCollections",
  MfaDisable = "MfaDisable",
  MfaEnable = "MfaEnable",
  RenameTag = "RenameTag",
  SaveListViewAs = "SaveListViewAs",
  SendCrewInvitationViaEmail = "SendCrewInvitationViaEmail",
  ShowInterestListing = "ShowInterestListing",
  ToggleNotificationDrawer = "ToggleNotificationDrawer",
  TransferVehicle = "TransferVehicle",
  UnarchiveVehicle = "UnarchiveVehicle",
  UpdateVehicleConsignment = "UpdateVehicleConsignment",
  UpdateVehicleLocation = "UpdateVehicleLocation",
  VaultAssignToHistoryRecord = "VaultAssignToHistoryRecord",
  VaultAssignToVehicle = "VaultAssignToVehicle",
  VaultEditMedia = "VaultEditMedia",
  VaultRecoverMedia = "VaultRecoverMedia",
  ViewCrewInvite = "ViewCrewInvite",
  ViewDataRoomMediaActivity = "ViewDataRoomMediaActivity",
  ViewDataRoomMembership = "ViewDataRoomMembership",
  WithdrawInterestListing = "WithdrawInterestListing",
}

export enum EncryptionTopics {
  DataEncryptionKeyAvailableForDecryption = "EncryptionDataEncryptionKeyAvailableForDecryption",
  DataEncryptionKeyAvailableForDownload = "EncryptionDataEncryptionKeyAvailableForDownload",
  DataEncryptionKeyAvailableForEncryption = "EncryptionDataEncryptionKeyAvailableForEncryption",
  DataEncryptionKeyAvailableForUpload = "EncryptionDataEncryptionKeyAvailableForUpload",
  DataEncryptionKeyRequired = "EncryptionDataEncryptionKeyRequired",
  ExistingFileDecrypted = "EncryptionExistingFileDecrypted",
  ExistingFileEncrypted = "EncryptionExistingFileEncrypted",
  PassphraseRequired = "EncryptionPassphraseRequired",
}

export const EventTopics = {
  TextDetectionCreated: "TextDetectionCreated",
  TextDetectionUpdated: "TextDetectionUpdated",
} as const;

export type MessageOnNavigation<T = unknown> = {
  message: PubSubJS.Message;
  data?: T;
};
