import { PropsWithChildren, ReactNode,Suspense } from "react";

import { DelayedFallback } from "./DelayedFallback";

export const SuspenseWrapper = ({
  children,
  fallback = <DelayedFallback />,
}: PropsWithChildren<{
  fallback?: ReactNode;
}>) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};
