import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Reminder } from "typings/Reminder";
import { DialogTopics, ReminderTopics } from "typings/Topics";
import { Vehicle } from "typings/Vehicle";

const ReminderModal = lazy(
  () => import("components/Reminder/dialogs/ReminderModal"),
);

export const ReminderDialogs = () => {
  const { object: toEdit, handleClose } = useModalWithSubscription<{
    reminder: Reminder;
    vehicle: Vehicle;
    ownerId: string;
  }>(DialogTopics.EditReminder, ReminderTopics.Updated);

  return (
    <SuspenseWrapper>
      {toEdit && <ReminderModal {...toEdit} onClose={handleClose} />}
    </SuspenseWrapper>
  );
};
