import Box, { BoxProps } from "@mui/material/Box";
import styled from "@mui/system/styled";

export const ActionBoxResponsive = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  flexGrow: 1,
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
  },
}));
