import { ReactNode } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { DialogCloseButton } from "components/DialogCloseButton/DialogCloseButton";

type DialogHeaderProps = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  onClose?: () => void;
  icon?: IconDefinition;
};

export const DialogHeader = ({
  title = "",
  subtitle,
  onClose,
  icon,
}: DialogHeaderProps) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        px: 3,
        py: 1.5,
        borderTop: `2px solid ${theme.palette.divider}`,
      }}
    >
      <Stack sx={{ alignItems: "center", gap: 1, flexDirection: "row" }}>
        {icon && (
          <FontAwesomeIcon
            fixedWidth
            color={theme.palette.primary.main}
            icon={icon}
          />
        )}

        <Typography sx={{ alignSelf: "center" }} variant="h5">
          {title}
        </Typography>

        {!!onClose && (
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            <DialogCloseButton onClose={onClose} />
          </Box>
        )}
      </Stack>
      {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
    </Stack>
  );
};
