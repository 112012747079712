import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Entity } from "typings/Entity";
import { CollectionTopics, DialogTopics } from "typings/Topics";

const CollectionManageVehiclesModal = lazy(
  () => import("./CollectionManageVehiclesModal"),
);
const SaveCollectionModal = lazy(() => import("./SaveCollectionModal"));

export const CollectionDialogs = () => {
  const { object: toManage, handleClose: handleCloseManage } =
    useModalWithSubscription<Entity>(
      DialogTopics.ManageCollection,
      CollectionTopics.VehiclesUpdated,
    );

  const { object: toEdit, handleClose: handleCloseEdit } =
    useModalWithSubscription<Entity>(
      DialogTopics.EditCollection,
      CollectionTopics.Updated,
    );

  return (
    <SuspenseWrapper>
      {toManage && (
        <CollectionManageVehiclesModal
          {...toManage}
          onClose={handleCloseManage}
        />
      )}

      {toEdit && <SaveCollectionModal {...toEdit} onClose={handleCloseEdit} />}
    </SuspenseWrapper>
  );
};
