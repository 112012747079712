import { ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { ClearIcon } from "@mui/x-date-pickers";

import { TextField, TextFieldProps } from "./TextField";

export type TextFieldControlledProps = TextFieldProps & {
  clearable?: boolean;
  name: string;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
};

type ClearButtonProps = {
  onClick: () => void;
  children?: ReactNode;
  value?: string;
};

const ClearButton = ({ onClick, children, value }: ClearButtonProps) => (
  <InputAdornment
    position="end"
    sx={{ display: "inline-flex", alignItems: "center" }}
  >
    <>
      {value && (
        <IconButton size="small" onClick={onClick}>
          <ClearIcon fontSize="small" />
        </IconButton>
      )}

      {children && children}
    </>
  </InputAdornment>
);

export const TextFieldControlled = ({
  clearable,
  error: errorProp,
  helperText,
  name,
  slotProps,
  startAdornment,
  endAdornment,
  ...rest
}: TextFieldControlledProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value = "" },
        fieldState: { error, invalid },
      }) => (
        <TextField
          {...rest}
          error={invalid || !!errorProp}
          helperText={error?.message ?? helperText}
          name={name}
          slotProps={{
            htmlInput: {
              "data-testid": `${name}-input`,
              ...slotProps?.htmlInput,
            },
            input: {
              ...slotProps?.input,
              startAdornment,
              endAdornment: (
                <Stack direction="row" sx={{ alignItems: "center", gap: 0.5 }}>
                  {clearable && (
                    <ClearButton value={value} onClick={() => onChange("")} />
                  )}
                  {endAdornment && endAdornment}
                </Stack>
              ),
            },
          }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    />
  );
};
