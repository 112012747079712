import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { usePublishMessageOnNavigation } from "hooks/useNavigation";
import { Entity } from "typings/Entity";
import { HistoryRecord } from "typings/HistoryRecord";
import { HistoryRecordType } from "typings/HistoryRecordType";
import { DialogTopics, HistoryRecordTopics } from "typings/Topics";
import { Vehicle } from "typings/Vehicle";

const HistoryRecordModal = lazy(
  () => import("components/HistoryRecord/dialogs/HistoryRecordModal"),
);
const EditGalleryModal = lazy(
  () => import("components/EditGalleryModal/EditGalleryModal"),
);

export const HistoryRecordDialogs = () => {
  const { object: toEdit, handleClose: handleCloseEditHistoryRecord } =
    useModalWithSubscription<{
      ownerId?: string;
      preselectedType?: HistoryRecordType;
      record?: HistoryRecord;
      vehicle?: Vehicle;
    }>(DialogTopics.EditHistoryRecord, HistoryRecordTopics.Updated);

  const {
    object: toEditGallery,
    handleClose: handleCloseEditHistoryRecordGallery,
  } = useModalWithSubscription<Entity>(DialogTopics.EditHistoryRecordGallery);

  usePublishMessageOnNavigation();

  return (
    <SuspenseWrapper>
      {!!toEdit && (
        <HistoryRecordModal
          {...toEdit}
          onClose={handleCloseEditHistoryRecord}
        />
      )}

      {toEditGallery ? (
        <EditGalleryModal
          {...toEditGallery}
          entityType="historyRecord"
          onClose={handleCloseEditHistoryRecordGallery}
        />
      ) : null}
    </SuspenseWrapper>
  );
};
