import { lazy } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { Layout } from "components/Layout/Layout";
import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import {
  CONFIRM_EMAIL,
  CONFIRM_MFA,
  EARLY_ACCESS,
  LOGIN,
  NOT_FOUND,
  RESET_PASSWORD,
  SIGNUP,
  VIEW,
} from "constants/routes";
import { useLoggedInRootPath } from "hooks/useLoggedInRootPath";

const BIPage = lazy(() => import("pages/bi/BIPage"));
const ChangePasswordPage = lazy(
  () => import("pages/change-password/ChangePasswordPage"),
);
const ConfirmEmailPage = lazy(
  () => import("pages/confirm-email/ConfirmEmailPage"),
);
const ConfirmMfaPage = lazy(() => import("pages/confirm-mfa/ConfirmMfaPage"));
const KesselPage = lazy(() => import("pages/kessel/KesselPage"));
// const ListingPage = lazy(
//   () => import("pages/data-room/dataRoomId/PublicDataRoomPage"),
// );
const LoginPage = lazy(() => import("pages/login/LoginPage"));
const NotFoundPage = lazy(() => import("pages/not-found/NotFoundPage"));
const ResetPasswordPage = lazy(
  () => import("pages/reset-password/ResetPasswordPage"),
);
const SignUpPage = lazy(() => import("pages/signup/SignUpPage"));
const ViewPage = lazy(() => import("pages/view/ViewPage"));
const PublicAppBar = lazy(() => import("components/AppBar/PublicAppBar"));
const UnsubscribePage = lazy(() => import("pages/unsubscribe/UnsubscribePage"));

const ContainedLayout = () => (
  <Layout onlyHorizontalAppBar AppBar={PublicAppBar}>
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NoAppBarLayout = () => (
  <Layout
    AppBar={undefined}
    sx={{ padding: 2, alignItems: "center", display: "flex", height: "100%" }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const NarrowCenteredLayout = () => (
  <Layout
    maxWidth="xs"
    sx={{ padding: 2, alignItems: "center", display: "flex", height: "100%" }}
  >
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  </Layout>
);

const Root = () => {
  const loggedInRootPath = useLoggedInRootPath();

  return <Navigate to={loggedInRootPath} />;
};

const routes = [
  <Route
    key={EARLY_ACCESS}
    element={<Navigate to={SIGNUP} />}
    path={EARLY_ACCESS}
  />,

  <Route key="contained" element={<ContainedLayout />}>
    <Route key={VIEW} element={<ViewPage />} path="/view/:token" />
  </Route>,

  <Route key="contained" element={<NarrowCenteredLayout />}>
    <Route key={SIGNUP} path={SIGNUP}>
      <Route index element={<SignUpPage />} />
    </Route>
    <Route
      key={RESET_PASSWORD}
      element={<ResetPasswordPage />}
      path={RESET_PASSWORD}
    />
    <Route
      key={`${RESET_PASSWORD}/:token`}
      element={<ChangePasswordPage />}
      path={`${RESET_PASSWORD}/:token`}
    />
    <Route
      key={`${CONFIRM_EMAIL}/:token`}
      element={<ConfirmEmailPage />}
      path={`${CONFIRM_EMAIL}/:token`}
    />
    <Route key={LOGIN} element={<LoginPage />} path={LOGIN} />,
    <Route
      key={CONFIRM_MFA}
      element={<ConfirmMfaPage />}
      path={CONFIRM_MFA}
    />,
    <Route key="bi" element={<BIPage />} path="/bi" />,
    <Route key="kessel" element={<KesselPage />} path="/kessel" />,
    <Route
      key="unsubscribe"
      element={<UnsubscribePage />}
      path="/unsubscribe"
    />
    <Route key="signUp" path="/signUp">
      <Route index element={<SignUpPage />} />
    </Route>
    <Route
      key="resetPassword"
      element={<ResetPasswordPage />}
      path="/resetPassword"
    />
    <Route
      key={`resetPassword/:token`}
      element={<ChangePasswordPage />}
      path={`resetPassword/:token`}
    />
    <Route
      key={`confirmEmail/:token`}
      element={<ConfirmEmailPage />}
      path={`confirmEmail/:token`}
    />
    <Route key="confirmMfa" element={<ConfirmMfaPage />} path="/confirmMfa" />
  </Route>,

  <Route key="root" element={<Root />} path="/" />,

  <Route key="not-found" element={<NoAppBarLayout />}>
    <Route element={<NotFoundPage />} path="/*" />
    <Route element={<NotFoundPage />} path={`${NOT_FOUND}/*`} />
  </Route>,
];

export function PublicRoutes() {
  return <>{routes.map((route) => route)}</>;
}
