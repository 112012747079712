import { useTheme } from "@mui/material/styles";
import { Variant } from "@mui/material/styles/createTypography";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";

export type TextFieldProps = MuiTextFieldProps;

export const TextField = ({ slotProps, sx, ...rest }: TextFieldProps) => {
  const theme = useTheme();

  const typographyVariant: Variant = "body1";

  return (
    <MuiTextField
      {...rest}
      size="small"
      slotProps={{
        ...slotProps,
        input: {
          ...slotProps?.input,
          style: {
            ...theme.typography[typographyVariant],
          },
        },
      }}
      sx={{
        ...sx,
        label: {
          ...theme.typography[typographyVariant],
        },
      }}
      variant="outlined"
    />
  );
};
