import { lazy } from "react";

import { SuspenseWrapper } from "components/Layout/SuspenseWrapper";
import { useModalWithSubscription } from "hooks/useModalWithSubscription";
import { Collector } from "typings/Collector";
import { DataRoom, DataRoomMembership } from "typings/DataRoom";
import { Media } from "typings/Media";
import { DataRoomTopics, DialogTopics } from "typings/Topics";
import { Vehicle } from "typings/Vehicle";

const DataRoomModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomModal"),
);
const DataRoomMembershipModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomMembershipModal"),
);
const DataRoomAddMembershipModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomAddMembershipModal"),
);
const DataRoomMediaActivityModal = lazy(
  () => import("components/DataRoom/dialogs/DataRoomMediaActivityModal"),
);

export const DataRoomDialogs = () => {
  const { object: toEdit, handleClose } = useModalWithSubscription<{
    dataRoom: DataRoom;
    vehicle: Vehicle;
    ownerId: string;
  }>(DialogTopics.EditDataRoom, DataRoomTopics.Updated);

  const { object: membershipToAdd, handleClose: handleCloseAdd } =
    useModalWithSubscription<{
      dataRoom: DataRoom;
    }>(DialogTopics.DataRoomAddMembership, DialogTopics.ViewDataRoomMembership);

  const { object: membershipToView, handleClose: handleCloseView } =
    useModalWithSubscription<DataRoomMembership>(
      DialogTopics.ViewDataRoomMembership,
    );

  const { object: toViewActivity, handleClose: handleCloseViewActivity } =
    useModalWithSubscription<{
      collectorId: Collector["id"];
      dataRoomId: DataRoom["id"];
      historyRecordId: Media["historyRecordId"];
      mediaId: Media["id"];
      memberId: DataRoomMembership["memberId"];
    }>(DialogTopics.ViewDataRoomMediaActivity);

  return (
    <>
      {toEdit && (
        <SuspenseWrapper>
          <DataRoomModal {...toEdit} onClose={handleClose} />
        </SuspenseWrapper>
      )}

      {membershipToAdd && (
        <SuspenseWrapper>
          <DataRoomAddMembershipModal
            {...membershipToAdd}
            onClose={handleCloseAdd}
          />
        </SuspenseWrapper>
      )}

      {membershipToView && (
        <SuspenseWrapper>
          <DataRoomMembershipModal
            membership={membershipToView}
            onClose={handleCloseView}
          />
        </SuspenseWrapper>
      )}

      {toViewActivity && (
        <SuspenseWrapper>
          <DataRoomMediaActivityModal
            {...toViewActivity}
            onClose={handleCloseViewActivity}
          />
        </SuspenseWrapper>
      )}
    </>
  );
};
